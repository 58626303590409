import React, { FC, useEffect, useRef } from 'react';

import exchangeSign from 'assets/png/exchange-sign.png';
import { TypographyVariants } from 'constants/shared/typography';
import { StartShareExchangesInvestmentItem } from 'interfaces';
import ShareExchangeInfoItem from 'page-components/deals/ShareExchangeInfoItem';
import Typography from 'shared-components/Typography';
import { getShareExchangesTotalInfo } from 'utils/deals';
import { formatNumberInputValue } from 'utils/shared';
interface Props {
  shareExchanges: StartShareExchangesInvestmentItem[];
  companyName: string | undefined;
}

const ShareExchangeRequestTransactionSummary: FC<Props> = ({ shareExchanges, companyName }) => {
  const {
    totalShares,
    totalExchangedShares,
    totalInvestmentPrice,
    offeredPrice,
    daxiaSharePrice,
    currentDaxiaInvestmentValue,
  } = getShareExchangesTotalInfo(shareExchanges);
  const itemsClassName = 'w-full mr-0 border-solid border-0 border-b border-grey-200 py-2 ';
  const companyNameRef1 = useRef<HTMLDivElement>(null);
  const companyNameRef2 = useRef<HTMLDivElement>(null);

  const updateHeight = () => {
    if (companyNameRef1.current && companyNameRef2.current) {
      const height1 = companyNameRef1.current.clientHeight;
      companyNameRef2.current.style.height = `${height1}px`;
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [companyName, shareExchanges]);

  useEffect(() => {
    updateHeight();
  }, [companyName, shareExchanges]);

  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-center w-full mb-4'>
        <div className='bg-white border border-grey-200 rounded-2xl px-6 py-2 3xl:py-3 min-h-min flex-1 m-0'>
          <Typography tag='h4' className='text-blue-600' variant={TypographyVariants.MEDIUM}>
            Original Investment
          </Typography>
          <div className='grid grid-cols-2 gap-4 p-2 2xl:mr-36 w-full mr-0 border-solid border-0 border-b border-grey-200'>
            <div className='text-grey-500 min-w-4xl max-w-4xl truncate whitespace-nowrap'>Company Name</div>
            <div className='text-black h-fit' ref={companyNameRef1}>
              {companyName ? companyName : ''}
            </div>
          </div>
          <ShareExchangeInfoItem className={itemsClassName} label='No. Shares to be Exchange' value={totalShares} />
          <ShareExchangeInfoItem
            className={itemsClassName}
            label='Offered price per Share'
            value={`£${formatNumberInputValue(offeredPrice, undefined, true)}`}
          />
          <ShareExchangeInfoItem
            className='w-full mr-0 pt-2 mb-1'
            label='Original Investment Value'
            value={`£${formatNumberInputValue(totalInvestmentPrice, undefined, true)}`}
          />
        </div>
        <div className='flex justify-center items-center flex-shrink-0 p-8'>
          <img src={exchangeSign} alt='Exchange Sign' style={{ height: 80, width: 80 }} />
        </div>
        <div className='bg-white border border-grey-200 rounded-2xl px-6 py-2 min-h-min flex-1 m-0'>
          <Typography tag='h4' className='text-blue-600' variant={TypographyVariants.MEDIUM}>
            New Daxia Investment
          </Typography>

          <div className='grid grid-cols-2 gap-4 p-2 2xl:mr-36 w-full mr-0 border-solid border-0 border-b border-grey-200 '>
            <div className='text-grey-500 min-w-4xl max-w-4xl truncate whitespace-nowrap'>Company Name</div>
            <div ref={companyNameRef2} className='text-black'>
              Daxia Limited
            </div>
          </div>
          <ShareExchangeInfoItem
            className={itemsClassName}
            label='No. Daxia Shares Received'
            value={totalExchangedShares}
          />
          <ShareExchangeInfoItem
            className={itemsClassName}
            label='Current Daxia Price per Share'
            value={`£${formatNumberInputValue(daxiaSharePrice, undefined, true)}`}
          />
          <ShareExchangeInfoItem
            className='w-full mr-0 pt-2 mb-1'
            label='Current Daxia Investment Value'
            value={`£${formatNumberInputValue(currentDaxiaInvestmentValue, undefined, true)}`}
          />
        </div>
      </div>
    </>
  );
};

export default ShareExchangeRequestTransactionSummary;
