import React, { FC, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import { getInviteLinkByCompanyId } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';
import { ButtonWithTooltip, Input } from 'shared-components';

import CompanyProfileInviteSkeleton from './CompanyProfileInviteSkeleton';

const DISABLED_INVITATION_BUTTON_TOOLTIP = 'disabled-invitation-button-tooltip';
const TOOLTIP_CONTENT = 'You are currently unable to invite investors';
interface Props {
  companyId?: string | number;
  hasActiveDeal?: boolean | undefined;
  className?: string;
  buttonClassName?: string;
  canInviteInvestors?: boolean;
}

const CompanyProfileInvite: FC<Props> = ({
  companyId,
  hasActiveDeal,
  className,
  buttonClassName,
  canInviteInvestors,
}) => {
  const [inviteLink, setInviteLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const inviteLinkInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const actionButtonText = useMemo(() => {
    if (isCopied) return 'Copied';

    if (inviteLink) return 'Copy Link';

    return 'Invite Link';
  }, [inviteLink, isCopied]);

  const handleCopyInviteLink = async () => {
    if (!inviteLinkInputRef?.current) return undefined;

    inviteLinkInputRef.current?.select();
    await navigator.clipboard.writeText(inviteLink);
    setIsCopied(true);

    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 10000);

    return () => clearTimeout(timer);
  };

  const handleFetchInviteLink = () => {
    if (!companyId) return;

    setIsLoading(true);
    dispatch(getInviteLinkByCompanyId(companyId))
      .unwrap()
      .then((link) => {
        setInviteLink(link);
      })
      .finally(() => setIsLoading(false));
  };

  const buttonAction = inviteLink ? handleCopyInviteLink : handleFetchInviteLink;

  if (!hasActiveDeal && hasActiveDeal !== undefined) return null;

  return (
    <div className={twMerge(cn('my-6 flex items-center', className))}>
      {isLoading || hasActiveDeal === undefined ? (
        <CompanyProfileInviteSkeleton />
      ) : (
        <>
          <Input
            className='mb-0 sm:mr-4'
            ref={inviteLinkInputRef}
            value={inviteLink}
            placeholder='Press the button'
            readOnly
          />
          <ButtonWithTooltip
            className={cn('w-150', buttonClassName)}
            wrapperClassName='w-fit'
            tooltipClassName='!mt-[-10px]'
            tooltipContent={!canInviteInvestors ? TOOLTIP_CONTENT : ''}
            anchorId={DISABLED_INVITATION_BUTTON_TOOLTIP}
            disabled={isCopied || !canInviteInvestors}
            onClick={buttonAction}
            positionStrategy='fixed'
            variant={ButtonVariants.PRIMARY}
          >
            {actionButtonText}
          </ButtonWithTooltip>
        </>
      )}
    </div>
  );
};

export default CompanyProfileInvite;
