import React, { useMemo } from 'react';

import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import useModal from 'hooks/use-modal/useModal';
import { Company } from 'interfaces';
import { AddCompanyModal, CompaniesItem } from 'page-components';
import { Button, SnippetLayout } from 'shared-components';
import { checkIsAdmin } from 'utils';

import FounderCompaniesSnippetWrapper from './FounderCompaniesSnippetWrapper';

interface Props {
  companies?: Company[] | null;
  isLoading: boolean;
}

const COMPANIES_PER_PAGE = 10;

const FounderCompaniesSnippet = ({ companies, isLoading }: Props) => {
  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { onOpen: handleOpenAddCompanyModal, ...addCompanyModalState } = useModal();

  return (
    <>
      {!isAdmin && <AddCompanyModal page={1} perPage={COMPANIES_PER_PAGE} {...addCompanyModalState} />}
      <SnippetLayout
        title='Companies'
        className='[&>div:first-child]:items-center overflow-auto'
        childrenClassName='overflow-hidden'
        customHeader={
          companies?.length && !isLoading && !isAdmin ? (
            <Button className='w-fit' onClick={handleOpenAddCompanyModal}>
              Add Company <PlusSvg className='ml-2 [&>path]:!fill-violet-600' />
            </Button>
          ) : null
        }
      >
        <div className='space-y-4 max-h-[400px] overflow-y-auto'>
          <FounderCompaniesSnippetWrapper
            companiesLength={companies?.length}
            isLoading={isLoading}
            skeletonItems={3}
            handleOpenAddCompanyModal={handleOpenAddCompanyModal}
          >
            {companies?.length
              ? companies?.map((company) => <CompaniesItem key={company.id} {...company} className='overflow-auto' />)
              : null}
          </FounderCompaniesSnippetWrapper>
        </div>
      </SnippetLayout>
    </>
  );
};

export default FounderCompaniesSnippet;
