import React, { FC, ReactNode, useEffect } from 'react';

import { ReactComponent as TrashCanSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  children: ReactNode;
  handleRemoveRow: VoidFunction;
  isOnlyOne?: boolean;
  watchClassOfSharesValue: string | undefined;
  handleSetCompanyPrice: VoidFunction;
};

const ShareExchangeRequestPageTableItem: FC<Props> = ({
  children,
  handleRemoveRow,
  watchClassOfSharesValue,
  handleSetCompanyPrice,
  isOnlyOne,
}) => {
  // eslint-disable-next-line
  useEffect(handleSetCompanyPrice, [watchClassOfSharesValue]);

  return (
    <tr>
      {children}
      <td>
        <Button
          className='w-auto pr-0 content-center'
          onClick={handleRemoveRow}
          variant={ButtonVariants.TRANSPARENT}
          disabled={isOnlyOne}
        >
          <TrashCanSvg />
        </Button>
      </td>
    </tr>
  );
};

export default ShareExchangeRequestPageTableItem;
