import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import Typography from 'shared-components/Typography';
import { formatNumberInputValue } from 'utils/shared';

interface Props {
  label: string;
  value: string | number;
  className?: string;
}

const ShareExchangeInfoItem: FC<Props> = ({ label, value, className }) => {
  return (
    <div className={twMerge('grid grid-cols-2 gap-4 pt-2 px-2 mr-20 2xl:mr-36', className)}>
      <div className='text-grey-500 min-w-4xl max-w-4xl truncate whitespace-nowrap'>{label}</div>
      <Typography className='text-black'>
        {typeof value === 'number' ? formatNumberInputValue(value) : value}
      </Typography>
    </div>
  );
};

export default ShareExchangeInfoItem;
