import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import imageSrc from 'assets/png/welcome-bg.png';
import { ReactComponent as ArrowRightSvg } from 'assets/svg/arrow-right.svg';
import { LocalStorageKeys } from 'constants/global';
import { TypographyVariants } from 'constants/shared/typography';
import { SIGN_UP_FLOWS } from 'constants/sign-up';
import { InviteUserType, UserTypes } from 'constants/user';
import { FOUNDER_WELCOME_PAGE_DESCRIPTION, INVESTOR_WELCOME_PAGE_DESCRIPTION } from 'constants/welcome';
import { selectInvitedUserSignUp } from 'modules/auth/selectors';
import { getUser } from 'modules/current-user/action';
import { selectIsActionLoading, selectUserEntityData, selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components';
import Typography from 'shared-components/Typography';
import {
  getDueDiligenceLink,
  getInvestmentsShareExchangeRequestForm,
  getTitleWithReplacedYourWord,
  setUserAccess,
} from 'utils';

const Welcome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isActionLoading = useAppSelector(selectIsActionLoading);
  const { inviteType, companyId } = useAppSelector(selectInvitedUserSignUp) || {};
  const userType = useAppSelector(selectUserType);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const welcomePageDescription = useMemo(() => {
    if (userType === UserTypes.INVESTOR)
      return getTitleWithReplacedYourWord(INVESTOR_WELCOME_PAGE_DESCRIPTION, entityName, isTypeEntity);

    return getTitleWithReplacedYourWord(FOUNDER_WELCOME_PAGE_DESCRIPTION, entityName, isTypeEntity);
  }, [userType]);

  const onContinue = () => {
    const authAccessToken = localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN);
    if (authAccessToken) {
      setUserAccess({ accessToken: authAccessToken });

      // TODO - Remove the "|| UserTypes.INVESTOR" condition when the "Who are you" page is added.
      localStorage.setItem(LocalStorageKeys.USER_TYPE, userType || UserTypes.INVESTOR);
      localStorage.setItem(LocalStorageKeys.IS_FIRST_SIGN_IN, '1');

      dispatch(getUser())
        .unwrap()
        .then(() => {
          // This setTimeout here is kind of crutch, because of current implementation when we fire getUser
          // After that we set user into storage and that's the problem. In the next step PagesWrapper will get
          // Update from redux storage with isAuth=true, and after that ProtectedRoute will do redirect for
          // AUTH_ROUTES to Dashboard page. Redux storage update goes after this function, so, first we will be
          // redirected on desired page, and after that we will be redirected to Dashboard page. To prevent it i used setTimeout
          // TODO - Remove setTimeout after updating setting user/ProtectedRoute component logic
          setTimeout(() => {
            const { flow: signUpFlow, companyId: signUpCompanyId } = JSON.parse(
              localStorage.getItem(LocalStorageKeys.SIGN_UP_FLOW_PARAMS) || '{}',
            );

            if (signUpFlow === SIGN_UP_FLOWS.CREATE_SHARE_EXCHANGE && signUpCompanyId) {
              localStorage.removeItem(LocalStorageKeys.SIGN_UP_FLOW_PARAMS);
              navigate(getInvestmentsShareExchangeRequestForm(signUpCompanyId, false));
              return;
            }

            if (companyId && inviteType === InviteUserType.DUE_DILIGENCE) {
              navigate(getDueDiligenceLink(companyId, false));
              return;
            }
          }, 200);
        });
    }
  };
  return (
    <div className='h-screen flex flex-col justify-center items-center bg-blue-800 relative'>
      <img className='absolute right-0 bottom-0 h-full' src={imageSrc} alt='test' />
      <div className=' max-w-[576px] text-center mx-4 z-10'>
        <Typography className='text-white text-[64px] leading-76' variant={TypographyVariants.LARGE} tag='h2'>
          Welcome to <span className='text-violet-600'>planD!</span>
        </Typography>
        <Typography className='mt-4 text-white'>{welcomePageDescription}</Typography>
        <Button onClick={onContinue} className='mt-10 sm:mt-20' isLoading={isActionLoading}>
          Next <ArrowRightSvg className='ml-2' />
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
