import React, { FC, ReactNode, useEffect } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { DueDiligenceSections } from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import useDueDiligenceComments from 'hooks/useDueDiligenceComments';
import {
  removeDueDiligenceEditingSections,
  setDueDiligenceHighlightUnsavedChanges,
} from 'modules/due-diligence/action';
import {
  selectCommentsByType,
  selectDueDiligenceEditingSections,
  selectHighlightUnsavedChanges,
  selectIsDueDiligenceArchived,
  selectIsDueDiligenceCalled,
  selectIsDueDiligenceEditable,
  selectIsDueDiligenceFinishedOrDeclined,
} from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, Comments } from 'shared-components';
import AddComment from 'shared-components/comments/AddComment';
import Typography from 'shared-components/Typography';

import ActionsButtons from '../ActionsButtons';
import DueDiligenceBlockTitle from './DueDiligenceBlockTitle';

type Props = {
  blockNumber?: string;
  title?: string;
  titleInformationTooltip?: ReactNode;
  blockClassName?: string;
  description?: string;
  onClickEdit?: VoidFunction;
  onClickSave?: VoidFunction;
  onClickDiscardChanges?: VoidFunction;
  children: ReactNode;
  footerContent?: ReactNode;
  descriptionClassName?: string;
  isLoading?: boolean;
  sectionType: DueDiligenceSections;
  hideComments?: boolean;
};

const DueDiligenceBlock: FC<Props> = ({
  blockClassName,
  children,
  title,
  titleInformationTooltip,
  blockNumber,
  description,
  onClickEdit,
  onClickSave,
  onClickDiscardChanges,
  footerContent,
  descriptionClassName,
  sectionType,
  isLoading = false,
  hideComments,
}) => {
  const dispatch = useAppDispatch();

  const highlightedUnsavedChanges = useAppSelector(selectHighlightUnsavedChanges);
  const comments = useAppSelector(selectCommentsByType(sectionType));
  const isCalled = useAppSelector(selectIsDueDiligenceCalled);
  const editingSections = useAppSelector(selectDueDiligenceEditingSections);
  const isDueDiligenceFinishedOrDeclined = useAppSelector(selectIsDueDiligenceFinishedOrDeclined);
  const isEditable = useAppSelector(selectIsDueDiligenceEditable);
  const isDueDiligenceArchived = useAppSelector(selectIsDueDiligenceArchived);

  const hasUnsavedChangesBlock = editingSections.includes(sectionType) && highlightedUnsavedChanges;

  const { handleAddComment, isActionLoading } = useDueDiligenceComments({
    sectionType,
  });

  const { isOpen, onOpen: handleCommentsOpen } = useModal(Boolean(comments?.length));

  const isCommentsOpen = Boolean((isCalled && isOpen) || comments?.length);

  const handleSave = () => {
    if (!onClickSave) return;

    dispatch(setDueDiligenceHighlightUnsavedChanges(false));
    onClickSave();
  };

  const handleDiscardChanges = () => {
    if (!onClickDiscardChanges) return;

    dispatch(setDueDiligenceHighlightUnsavedChanges(false));
    onClickDiscardChanges();
  };

  const handleEdit = () => {
    if (!onClickEdit) return;

    dispatch(setDueDiligenceHighlightUnsavedChanges(false));
    onClickEdit();
  };

  useEffect(() => {
    if (
      editingSections.includes(sectionType) &&
      (isDueDiligenceFinishedOrDeclined || !isEditable || isDueDiligenceArchived)
    )
      dispatch(removeDueDiligenceEditingSections(sectionType));
  }, [dispatch, editingSections, isDueDiligenceFinishedOrDeclined, isEditable, isDueDiligenceArchived, sectionType]);

  return (
    <div
      className={cn(
        'w-full flex flex-col bg-white border border-grey-200 rounded-2xl p-6 relative',
        { 'border-grey-300': !hasUnsavedChangesBlock, 'border-red-500': hasUnsavedChangesBlock },
        blockClassName,
      )}
    >
      {title && (
        <DueDiligenceBlockTitle
          highlightedUnsavedChanges={hasUnsavedChangesBlock}
          title={title}
          blockNumber={blockNumber}
          informationTooltip={titleInformationTooltip}
        />
      )}

      {description && <Typography className={twMerge('pt-2', descriptionClassName)}>{description}</Typography>}

      {children}

      {hasUnsavedChangesBlock && (
        <div className='mt-4 -mb-4 text-end text-red-500'>There are unsaved changes, Save now.</div>
      )}

      <div className='flex flex-col sm:flex-row mt-auto'>
        {footerContent}
        <ActionsButtons
          isCommentsOpen={isCommentsOpen}
          isLoading={isLoading}
          onClickAddComments={handleCommentsOpen}
          onClickEdit={handleEdit}
          onClickSave={handleSave}
          isCommentsLoading={!isCalled}
          onClickDiscardChanges={handleDiscardChanges}
          isCommentsExist={Boolean(comments?.length)}
          hidden={isDueDiligenceFinishedOrDeclined || isDueDiligenceArchived}
          hideComments={hideComments}
          hideEditButton
          withoutEditing
        >
          {onClickSave && (
            <Button
              isLoading={isLoading}
              variant={ButtonVariants.SECONDARY}
              className='w-full sm:w-[130px] ml-4 text-sm'
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </ActionsButtons>
      </div>

      <div className='border-t border-grey-300 mt-6 pt-4 flex-1'>
        <Comments<DueDiligenceSections>
          comments={comments}
          isOpen={isCommentsOpen}
          commentsFooterContent={
            <AddComment
              isLoading={isActionLoading}
              hidden={isDueDiligenceFinishedOrDeclined || isDueDiligenceArchived}
              className='sm:mt-6'
              onAddComment={handleAddComment}
            />
          }
        />
      </div>
    </div>
  );
};

export default DueDiligenceBlock;
