import React from 'react';

import {
  FieldArrayWithId,
  FieldArrayPath,
  UseFormWatch,
  UseFormSetValue,
  UseFieldArrayRemove,
  UseFormRegister,
  Control,
  FieldErrors,
  Path,
  PathValue,
} from 'react-hook-form';

import { StartShareExchangeFieldNames } from 'constants/share-exchange';
import {
  Option,
  StartShareExchangesInvestmentItem,
  StartShareExchangeWatchValues,
  DealPredefinedData,
  FormItem,
} from 'interfaces';
import ShareExchangeRequestPageTableItem from 'page-components/my-investments/start-investment-share-exchange-page/ShareExchangeRequestPageTableItem';
import FormContent from 'shared-components/FormContent';
import { calculateNoOfExchangesShares } from 'utils/investments';

interface ShareExchangeDetailsRowProps<TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] }> {
  deal: FieldArrayWithId<TFieldValues, FieldArrayPath<StartShareExchangesInvestmentItem>, 'id'>;
  dealIndex: number;
  dealsFields: FieldArrayWithId<TFieldValues, FieldArrayPath<StartShareExchangesInvestmentItem>, 'id'>[];
  watch: UseFormWatch<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  remove: UseFieldArrayRemove;
  getFields: (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) => FormItem[];
  classOfSharesOptions: Option[];
  closingDatesOptions: Option[];
  companyDealPredefinedData: DealPredefinedData | null;
  isFormDisabled?: boolean;

  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
}

const ShareExchangeDetailsRow = <TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] }>({
  deal,
  dealIndex,
  dealsFields,
  watch,
  setValue,
  remove,
  getFields,
  classOfSharesOptions,
  closingDatesOptions,
  companyDealPredefinedData,
  isFormDisabled,

  register,
  control,
  errors,
}: ShareExchangeDetailsRowProps<TFieldValues>) => {
  const dealsPrefix: `exchanges.${number}` = `exchanges.${dealIndex}`;

  const noOfShares = watch(`${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}` as Path<TFieldValues>);
  const fundSharePrice = watch(`${dealsPrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}` as Path<TFieldValues>);

  const watchValues = {
    noOfShares,
    fundSharePrice,
    companyPrice: watch(`${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}` as Path<TFieldValues>) || '0',
  };

  const watchClassOfShares = watch(
    `${dealsPrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}` as Path<TFieldValues>,
  ) as Option;

  const handleSetCompanyPrice = () => {
    const { companyPrice } =
      companyDealPredefinedData?.companySharePrice.find(
        ({ classOfShares }) => classOfShares === watchClassOfShares?.value,
      ) || {};

    if (companyPrice) {
      setValue(
        `${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}` as Path<TFieldValues>,
        companyPrice as PathValue<TFieldValues, Path<TFieldValues>>,
      );
      setValue(
        `${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}` as Path<TFieldValues>,
        calculateNoOfExchangesShares({
          companyPrice,
          noOfShares: `${noOfShares}`,
          fundSharePrice: `${fundSharePrice}`,
        }) as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }
  };

  return (
    <>
      <ShareExchangeRequestPageTableItem
        key={deal.id}
        isOnlyOne={dealsFields?.length === 1}
        handleRemoveRow={() => remove(dealIndex)}
        watchClassOfSharesValue={watchClassOfShares?.value}
        handleSetCompanyPrice={handleSetCompanyPrice}
      >
        <FormContent
          isDisabled={isFormDisabled}
          fields={getFields(
            watchValues as StartShareExchangeWatchValues,
            handleSetCompanyPrice,
            classOfSharesOptions,
            closingDatesOptions,
            dealIndex,
          )}
          register={register}
          control={control}
          errors={errors}
          anchorPrefix='start-investment-share-exchange-modal'
          isTableView={true}
          tableDataClassName='py-2'
        />
      </ShareExchangeRequestPageTableItem>
    </>
  );
};

export default ShareExchangeDetailsRow;
