import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ConstraintVariants } from 'constants/shared';
import { getCompanyById } from 'modules/companies/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  DashboardLayout,
  CompanyProfileDetails,
  CompanyProfileOfficers,
  CompanyProfileHoldersSnippet,
  CompanyProfileFinancialOverviewSnippet,
  CompanyProfileInvites,
} from 'page-components';
import { Constraint } from 'shared-components';
import { whetherToShowMockData } from 'utils';

import { useCurrentPath } from '../hooks/useCurrentPath';
import { selectCurrentCompanyData } from '../modules/companies/selectors';

const CompanyProfile = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { data } = useAppSelector(selectCurrentCompanyData);

  useEffect(() => {
    if (id) dispatch(getCompanyById({ id }));
  }, [id, dispatch]);

  useCurrentPath(data?.companyName);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <div className='flex flex-col xl:flex-row gap-x-6 xl:gap-x-10'>
          <div className='flex-1'>
            <CompanyProfileDetails />
            {whetherToShowMockData() && <CompanyProfileFinancialOverviewSnippet />}
          </div>
          <div className='flex-1'>
            <CompanyProfileOfficers />
            <CompanyProfileHoldersSnippet />
            <CompanyProfileInvites companyId={id} canInviteInvestors={data?.founderActions.canInviteInvestors} />
          </div>
        </div>
      </Constraint>
    </DashboardLayout>
  );
};

export default CompanyProfile;
