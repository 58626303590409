import React, { FC, ReactNode, useMemo } from 'react';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SortingArrow } from 'assets/svg/sorting-arrow.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { TypographyVariants } from 'constants/shared/typography';
import { Company } from 'interfaces';
import { ButtonDropdown, ButtonWithTooltip, LabelWithValue, ListItemWrapper } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getCompanyInvestorsLink, getCompanyProfileLink, getDueDiligenceLink, getNatureOfBusinessList } from 'utils';

const DISABLED_ACTIONS_BUTTON_TOOLTIP = 'disabled-actions-button-tooltip';

const DROPDOWN_BUTTON_CLASSNAME = 'basis-4/12 lg:basis-[200px] whitespace-nowrap';

interface Props extends Company {
  className?: string;
  customActions?: ReactNode;
}

const CompaniesItem: FC<Props> = ({
  id,
  companyName,
  natureOfBusiness,
  dateIncorporated,
  industry,
  canViewDueDiligence,
  canStartDueDiligence,
  confirmed,
  className,
  customActions,
  founderActions: { canInviteInvestors },
}) => {
  const navigate = useNavigate();

  const actions = useMemo(() => {
    const actionsList = [
      { id: 'view_company', title: 'View Company', action: () => navigate(getCompanyProfileLink(id)) },
    ];

    if (canStartDueDiligence)
      actionsList.push({
        id: 'start_due_diligence',
        title: 'Start Due Diligence',
        action: () => navigate(getDueDiligenceLink(id)),
      });

    if (canViewDueDiligence && !canStartDueDiligence)
      actionsList.push({
        id: 'view_due_diligence',
        title: 'View Due Diligence',
        action: () => navigate(getDueDiligenceLink(id)),
      });

    if (canInviteInvestors) {
      actionsList.push({
        id: 'invite_investors',
        title: 'Invite Investors',
        action: () => navigate(getCompanyInvestorsLink(id), { state: { companyName } }),
      });
    }

    return actionsList;
  }, [canStartDueDiligence, canViewDueDiligence, companyName, id, navigate, canInviteInvestors]);

  const formattedDateIncorporated = dateIncorporated ? format(new Date(dateIncorporated), DAY_FORMAT_WITH_SLASHES) : '';

  return (
    <ListItemWrapper className={className}>
      <div className='flex basis-8/12 lg:basis-10/12'>
        <Typography variant={TypographyVariants.BODY_SMALL} className='flex flex-col mr-6 basis-4/12'>
          Company Name
          <Typography tag='span' className='mt-1' variant={TypographyVariants.MEDIUM}>
            {companyName}
          </Typography>
        </Typography>
        <div className='flex flex-wrap basis-8/12 ml-4'>
          <LabelWithValue className='mb-4' title='Industry' content={industry} withLine />
          <LabelWithValue className='mb-4' title='Date Incorporated' content={formattedDateIncorporated} withLine />
          <LabelWithValue
            className='mb-4'
            title='Nature of business(SIC)'
            content={getNatureOfBusinessList(natureOfBusiness || [])}
            withLine
          />
        </div>
      </div>
      {customActions || (
        <>
          {confirmed ? (
            <ButtonDropdown className={DROPDOWN_BUTTON_CLASSNAME} actions={actions} />
          ) : (
            <ButtonWithTooltip
              wrapperClassName={DROPDOWN_BUTTON_CLASSNAME}
              anchorId={DISABLED_ACTIONS_BUTTON_TOOLTIP}
              tooltipContent='Please wait for confirmation to view'
              disabled
            >
              <div>Actions</div>
              <SortingArrow className='ml-4 fill-grey-100 stroke-grey-100' />
            </ButtonWithTooltip>
          )}
        </>
      )}
    </ListItemWrapper>
  );
};

export default CompaniesItem;
