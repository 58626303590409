import React, { useEffect } from 'react';

import { UseFormReset } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { DEAL_STATUSES } from 'constants/deals';
import { StartShareExchangesInvestmentItem, StartShareExchangesInvestmentsFormValues } from 'interfaces';
import { getCompanyDealPredefinedData } from 'modules/deals/action';
import { createCompaniesShareExchanges } from 'modules/investments/action';
import { selectCompaniesWithInvestments } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import ShareExchangeRequestFields from 'page-components/my-investments/start-investment-share-exchange-page/ShareExchangeRequestFields';
import { getDealDetailsLink } from 'utils';

const ShareExchangeRequestForm = () => {
  const { companyId } = useParams();
  const companiesWithInvestments = useAppSelector(selectCompaniesWithInvestments);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Its used for redirecting from sign-in and sign-up pages, when invitation link has CSE flow

    if (companyId) {
      const companyInvestment = companiesWithInvestments.find((c) => {
        return Number(c.id) === Number(companyId);
      });
      let draftDealId;
      if (companyInvestment?.seStatus === DEAL_STATUSES.PENDING) {
        draftDealId = companyInvestment.seId;
      }
      dispatch(getCompanyDealPredefinedData({ companyId: Number(companyId), draftDealId }));
    }
  }, [dispatch, companyId, companiesWithInvestments]);

  const onSuccessStartShareExchange = (
    shareExchanges: StartShareExchangesInvestmentItem[],
    companyId: number,
    loadingCallback: VoidFunction,
    reset: UseFormReset<StartShareExchangesInvestmentsFormValues>,
    investmentIds?: number[],
    draftDealId?: number,
  ) => {
    dispatch(createCompaniesShareExchanges({ companyId, shareExchanges, investmentIds, draftDealId }))
      .unwrap()
      .then((response) => {
        loadingCallback();
        reset();
        navigate(getDealDetailsLink(response.id));
      })
      .finally(loadingCallback);
  };

  return (
    <DashboardLayout>
      <ShareExchangeRequestFields onSuccessStartShareExchange={onSuccessStartShareExchange} />
    </DashboardLayout>
  );
};

export default ShareExchangeRequestForm;
