import React, { useEffect, useMemo } from 'react';

import { endOfDay, isBefore } from 'date-fns';
import {
  FieldArrayWithId,
  Path,
  UseFormWatch,
  FieldArrayPath,
  UseFormSetValue,
  PathValue,
  UseFieldArrayRemove,
  UseFormRegister,
  Control,
  FieldErrors,
  UseFieldArrayAppend,
} from 'react-hook-form';

import {
  SHARE_EXCHAGE_REQUEST_FORM_TITLE,
  SHARE_EXCHANGE_DETAILS_TABLE_COLUMNS,
  StartShareExchangeFieldNames,
} from 'constants/share-exchange';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import {
  DealPredefinedData,
  FormItem,
  Option,
  StartShareExchangesInvestmentItem,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
} from 'interfaces';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import ShareExchangeDetailsRow from 'page-components/deals/share-exchanges/ShareExchangeDetailsRow';
import { Button, Constraint, SnippetLayout, TitleWithDescription } from 'shared-components';
import Table from 'shared-components/table';
import { getTitleWithoutMyWord } from 'utils';
import { calculateNoOfExchangesShares } from 'utils/investments';

import StartInvestmentShareExchangeAddNewRow from '../../my-investments/start-investment-share-exchange-modal/StartInvestmentShareExchangeAddNewRow';
import ShareExchangeRequestFeesInfo from './ShareExchangeRequestFeesInfo';
import ShareExchangeRequestTransactionSummary from './ShareExchangeRequestTransactionSummary';

interface Props<
  TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] },
  TFieldArrayName extends FieldArrayPath<StartShareExchangesInvestmentItem>,
> {
  getFields: (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) => FormItem[];
  dealsFields: FieldArrayWithId<TFieldValues, TFieldArrayName, 'id'>[];
  shareExchanges: StartShareExchangesInvestmentItem[];
  companyDealPredefinedData: DealPredefinedData | null;
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  append: UseFieldArrayAppend<StartShareExchangesInvestmentsFormValues>;
  remove: UseFieldArrayRemove;
  isFormDisabled?: boolean;
  isLoading: boolean;
  onSubmit: VoidFunction;
}

const ShareExchangeRequestDetailsCommonFields = <
  TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] },
  TFieldArrayName extends FieldArrayPath<StartShareExchangesInvestmentItem>,
>({
  getFields,
  dealsFields,
  shareExchanges,
  companyDealPredefinedData,
  register,
  control,
  watch,
  errors,
  setValue,
  append,
  remove,
  isLoading,
  onSubmit,
}: Props<TFieldValues, TFieldArrayName>) => {
  const classOfSharesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.companySharePrice?.map(({ classOfShares }) => ({
        label: classOfShares,
        value: classOfShares,
      })) || []
    );
  }, [companyDealPredefinedData?.companySharePrice]);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const closingDatesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.closingDatesDropDown?.map(({ closingDate }) => ({
        label: closingDate,
        value: closingDate,
        isDisabled: isBefore(endOfDay(new Date(closingDate)), endOfDay(new Date())),
      })) || []
    );
  }, [companyDealPredefinedData?.closingDatesDropDown]);

  const appendNewInvestmentsRow = () => {
    const [{ companyPrice }] = companyDealPredefinedData?.companySharePrice || [];

    const { value: classOfShareValue } = classOfSharesOptions[0];
    const { value: closingDateValue } = closingDatesOptions[0];

    append({
      classOfShares: classOfSharesOptions?.length === 1 ? { label: classOfShareValue, value: classOfShareValue } : null,
      companyPrice: companyPrice || '0',
      fundSharePrice: companyDealPredefinedData?.fundSharePrice || '',
      noExchangedShares: '',
      noOfShares: '',
      closingDate: closingDatesOptions?.length === 1 ? { label: closingDateValue, value: closingDateValue } : null,
    });
  };

  useEffect(() => {
    const firstExchangePrefix = `exchanges.0`;
    const [{ companyPrice }] = companyDealPredefinedData?.companySharePrice || [];

    const noOfShares = watch(
      `${firstExchangePrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}` as Path<TFieldValues>,
    );
    const fundSharePrice = watch(
      `${firstExchangePrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}` as Path<TFieldValues>,
    );

    if (classOfSharesOptions?.length === 1) {
      const { value } = classOfSharesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}` as Path<TFieldValues>,
        optionToSet as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }

    if (closingDatesOptions?.length === 1) {
      const { value } = closingDatesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.CLOSING_DATE}` as Path<TFieldValues>,
        optionToSet as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }

    if (companyPrice) {
      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}` as Path<TFieldValues>,
        companyPrice as PathValue<TFieldValues, Path<TFieldValues>>,
      );
      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}` as Path<TFieldValues>,
        calculateNoOfExchangesShares({
          companyPrice,
          noOfShares: `${noOfShares}`,
          fundSharePrice: `${fundSharePrice}`,
        }) as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }
  }, [
    setValue,
    shareExchanges,
    classOfSharesOptions,
    closingDatesOptions,
    companyDealPredefinedData?.companySharePrice,
    watch,
  ]);

  return (
    <>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED_LOWER} className='mt-10 pt-2'>
        <TitleWithDescription
          className='mb-2 3xl:mb-4'
          title={getTitleWithoutMyWord(SHARE_EXCHAGE_REQUEST_FORM_TITLE, isTypeEntity)}
          titleVariant={TypographyVariants.SEMI_LARGE}
        />
        <SnippetLayout
          title={companyDealPredefinedData?.companyName}
          description='Please confirm the class of shares and number of shares you’d like to exchange'
          className='py-2 3xl:py-4 [&>div_p]:pb-0'
          childrenClassName='mt-0'
        >
          <div className='flex flex-col 3xl:gap-2'>
            <Table columns={SHARE_EXCHANGE_DETAILS_TABLE_COLUMNS}>
              {dealsFields?.map((deal, dealIndex) => (
                <ShareExchangeDetailsRow
                  key={deal.id}
                  deal={deal}
                  dealIndex={dealIndex}
                  dealsFields={dealsFields}
                  watch={watch}
                  setValue={setValue}
                  remove={remove}
                  getFields={getFields}
                  classOfSharesOptions={classOfSharesOptions}
                  closingDatesOptions={closingDatesOptions}
                  companyDealPredefinedData={companyDealPredefinedData}
                  register={register}
                  control={control}
                  errors={errors}
                />
              ))}
            </Table>
            <StartInvestmentShareExchangeAddNewRow addNewRow={appendNewInvestmentsRow} />
          </div>
        </SnippetLayout>
      </Constraint>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED_LOWER} className='mt-6 3xl:mt-10'>
        <TitleWithDescription
          className='mb-2 3xl:mb-4'
          title={'Transaction Summary'}
          titleVariant={TypographyVariants.SEMI_LARGE}
        />
        <ShareExchangeRequestTransactionSummary
          shareExchanges={shareExchanges}
          companyName={companyDealPredefinedData?.companyName}
        />
        <Button
          isLoading={isLoading}
          variant={ButtonVariants.PRIMARY}
          className='w-full mt-2 3xl:my-6 '
          onClick={onSubmit}
          disabled={isLoading}
        >
          Initiate Share Exchange
        </Button>
        {companyDealPredefinedData?.fee && <ShareExchangeRequestFeesInfo fee={companyDealPredefinedData.fee} />}
      </Constraint>
    </>
  );
};

export default ShareExchangeRequestDetailsCommonFields;
