import React from 'react';

import { DealFee } from 'interfaces';

interface Props {
  fee: DealFee;
}

const ShareExchangeRequestFeesInfo = ({ fee }: Props) => {
  return (
    <div className='fees-frame'>
      Fees apply, the greater of{' '}
      <b>
        £{fee.feeMinimum} or {fee.feeRate}%
      </b>{' '}
      of the gross initial investment value.
    </div>
  );
};

export default ShareExchangeRequestFeesInfo;
