import { useState } from 'react';

import useModal from 'hooks/use-modal/useModal';
import { CustomHookWithActionsReturnStringValues } from 'interfaces';
import { reimportDeal } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';

const useReimportDeal = ({ onSuccess }: { onSuccess?: VoidFunction }): CustomHookWithActionsReturnStringValues => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const modalProps = useModal();

  const handleReimportDeal = (key: string | string[]) => {
    if (!key || Array.isArray(key)) return;

    setIsLoading(true);
    dispatch(reimportDeal({ key }))
      .unwrap()
      .then(onSuccess)
      .finally(() => {
        setIsLoading(false);
        modalProps.onClose();
      });
  };

  return [handleReimportDeal, modalProps, isLoading];
};

export default useReimportDeal;
