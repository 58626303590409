import { ReactComponent as AddInvestmentsCompaniesSvg } from 'assets/svg/add-investments-companies.svg';
import { ReactComponent as CompanySvg } from 'assets/svg/company.svg';
import { ReactComponent as DocumentsSvg } from 'assets/svg/documents.svg';
import { ReactComponent as FolderSvg } from 'assets/svg/folder.svg';
import { ReactComponent as FoundersSvg } from 'assets/svg/founders.svg';
import { ReactComponent as FundsSvg } from 'assets/svg/funds.svg';
import { ReactComponent as HomeSvg } from 'assets/svg/home.svg';
import { ReactComponent as InfoSvg } from 'assets/svg/info.svg';
import { ReactComponent as InvestorsSvg } from 'assets/svg/investors.svg';
import { ReactComponent as MyInvestmentsSvg } from 'assets/svg/my-investments.svg';
import { ReactComponent as SavingsSvg } from 'assets/svg/savings.svg';
import { ReactComponent as SettingsSvg } from 'assets/svg/settings.svg';
import { ReactComponent as ShareSvg } from 'assets/svg/share.svg';
import { ReactComponent as SyncSvg } from 'assets/svg/sync.svg';
import { ReactComponent as UserSvg } from 'assets/svg/user.svg';
import { ReactComponent as WalletSvg } from 'assets/svg/wallet.svg';
import { ReactComponent as FAQSvg } from 'assets/svg/warning-outlined.svg';
import { ReactComponent as WorldSvg } from 'assets/svg/world.svg';
import { LinkItem, Route } from 'interfaces';
import {
  BusinessProfile,
  CompanyConfirm,
  CompanyProfile,
  Dashboard,
  Deals,
  Documents,
  ForgotPassword,
  FounderInvite,
  Investments,
  InvestorsInvite,
  MyCompanies,
  MyInvestments,
  SetPassword,
  ResetPasswordSuccess,
  SignIn,
  SignUp,
  SignUpInvitedUser,
  TermsAndConditions,
  DealDetails,
  User,
  Users,
  Welcome,
  Settings,
  NotFound,
  Invoices,
  AdminDeals,
  WhoAreYou,
  AdminCompanies,
  Founders,
  Investors,
  FinishRegistration,
  ShareExchangeRequests,
  ShareExchangeReview,
  AdminShareExchangeReview,
  DueDiligence,
  CompanyIac,
  AuditLogs,
  DealParameters,
  BusinessSettings,
  AllDealsParameters,
  AdminCompanyProfile,
  CompanyInvestors,
  SignificantControlCompany,
  Funds,
  ArchivedCompanyDueDiligence,
  ArchivedCompanyIac,
  ShareExchangeProcess,
  Investor,
  EntityAuditLogs,
  Founder,
  Notifications,
  ShareExchangeRequestForm,
} from 'pages';

import ConfirmEmailChange from '../pages/ConfirmEmailChange';
import { getTitleWithoutMyWord } from '../utils';
import { CONTACTS_LINK, FAQ_LINK, RESOURCES_LINK } from './links';
import { InvestorSubTypes, UserTypes } from './user';

export const ROUTES: Record<string, string> = {
  home: '/*',
  dashboard: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/password/reset/:token/:email',
  resetPasswordSuccess: '/reset-password-success',
  signUp: '/sign-up',
  signUpInvitedUser: '/sign-up-invited-user/:hash',
  finishRegistration: '/finish-registration/:hash',
  whoAreYou: '/who-are-you',
  signIn: '/sign-in',
  confirmEmailChange: 'confirmation-email/:id',
  companyConfirm: '/company-confirm',
  founderInvite: '/founder-invite',
  investorsInvite: '/investors-invite',
  investments: '/investments',
  myInvestments: '/my-investments',
  welcome: '/welcome',
  users: '/users',
  termsAndConditions: '/terms-and-conditions',
  user: '/users/:id',
  documents: '/documents',
  myCompanies: '/my-companies',
  companyProfile: '/companies/:id',
  companies: '/companies',
  deals: '/deals',
  dealDetails: '/deals/:id',
  plainlyDealDetails: '/deals/plainly',
  dealsParameters: '/deals-parameters',
  dealParameters: '/deals/:id/parameters',
  settings: '/settings',
  businessProfile: '/business-profile',
  admin: '/admin',
  connectLinkedIn: '/connect-linkedin',
  invoices: '/invoices',
  founders: '/founders',
  founder: '/founder/:id',
  investors: '/investors',
  investor: '/investors/:id',
  shareExchangeRequestForm: '/share-exchange-request/:companyId',
  shareExchangeRequests: '/share-exchange-requests',
  shareExchangeReview: '/share-exchange-review/:id',
  dueDiligence: '/due-diligence/:id',
  companyDueDiligence: '/companies/:id/due-diligence/:dueDiligenceId', // Due Diligence by DD id
  companyIac: '/companies/:id/iac/:iacId', // Iac by IAC id
  companiesIac: '/companies/:id/iac',
  companyInvestors: '/companies/:id/investors',
  significantControlCompany: '/companies/:id/significant-control',
  archivedDueDiligence: '/companies/:id/due-diligence/archives',
  archivedCompanyIac: '/companies/:id/iac/archives',
  auditLogs: '/audit-logs',
  entityAuditLogs: '/entity-audit-logs',
  businessSettings: '/business-settings',
  funds: '/funds',
  shareExchangeProcess: '/share-exchange-process',
  notifications: '/notifications',
  notFound: '*',
};

export const SIGN_UP_ROUTES_WITHOUT_REGISTER_LINK = [
  ROUTES.signUp,
  ROUTES.signUpInvitedUser,
  ROUTES.whoAreYou,
  ROUTES.finishRegistration,
  ROUTES.forgotPassword,
];

export const SIGN_UP_FLOW_LIST: string[] = [ROUTES.companyConfirm, ROUTES.welcome];

export const TERMS_AND_CONDITIONS_ROUTE = {
  path: ROUTES.termsAndConditions,
  element: TermsAndConditions,
};

export const GLOBAL_ROUTES: Route[] = [
  TERMS_AND_CONDITIONS_ROUTE,
  {
    path: ROUTES.notFound,
    element: NotFound,
  },
  { path: ROUTES.whoAreYou, element: WhoAreYou },
];

export const MAIN_ROUTES: Route[] = [
  {
    path: ROUTES.dashboard,
    element: Dashboard,
  },
  {
    path: ROUTES.documents,
    element: Documents,
  },
  {
    path: ROUTES.dealDetails,
    element: DealDetails,
  },
  {
    path: ROUTES.businessProfile,
    element: BusinessProfile,
  },
  {
    path: ROUTES.settings,
    element: Settings,
  },
  {
    path: ROUTES.companyProfile,
    element: CompanyProfile,
  },
  {
    path: ROUTES.confirmEmailChange,
    element: ConfirmEmailChange,
  },
];

export const FOUNDER_ROUTES: Route[] = [
  {
    path: ROUTES.myCompanies,
    element: MyCompanies,
  },
  { path: ROUTES.dueDiligence, element: DueDiligence },
  { path: ROUTES.significantControlCompany, element: SignificantControlCompany },
  {
    path: ROUTES.companyInvestors,
    element: CompanyInvestors,
  },
  {
    path: ROUTES.notifications,
    element: Notifications,
  },
];

export const INVESTOR_ROUTES = (userType: InvestorSubTypes): Route[] => [
  ...(userType !== InvestorSubTypes.ENTITY
    ? [
        {
          path: ROUTES.entityAuditLogs,
          element: EntityAuditLogs,
        },
      ]
    : []),
  {
    path: ROUTES.deals,
    element: Deals,
  },
  {
    path: ROUTES.myInvestments,
    element: MyInvestments,
  },
  {
    path: ROUTES.investments,
    element: Investments,
  },
  {
    path: ROUTES.shareExchangeRequestForm,
    element: ShareExchangeRequestForm,
  },
  {
    path: ROUTES.shareExchangeReview,
    element: ShareExchangeReview,
  },
  { path: ROUTES.plainlyDealDetails, element: DealDetails },
  { path: ROUTES.shareExchangeProcess, element: ShareExchangeProcess },
  {
    path: ROUTES.notifications,
    element: Notifications,
  },
];

export const COMMON_AUTH_ROUTES: Route[] = [
  {
    path: ROUTES.signIn,
    element: SignIn,
  },
];

export const FM_USER_ROUTES = [
  {
    path: ROUTES.dashboard,
    element: Dashboard,
  },
  {
    path: ROUTES.companies,
    element: AdminCompanies,
  },
  {
    path: ROUTES.documents,
    element: Documents,
  },
  {
    path: ROUTES.dueDiligence,
    element: DueDiligence,
  },
  {
    path: ROUTES.companiesIac,
    element: CompanyIac,
  },
  {
    path: ROUTES.significantControlCompany,
    element: SignificantControlCompany,
  },
  { path: ROUTES.archivedDueDiligence, element: ArchivedCompanyDueDiligence },
  { path: ROUTES.archivedCompanyIac, element: ArchivedCompanyIac },
  {
    path: ROUTES.notifications,
    element: Notifications,
  },
];

export const MAIN_ADMIN_ROUTES = [
  {
    path: ROUTES.dashboard,
    element: Dashboard,
  },
  {
    path: ROUTES.users,
    element: Users,
  },
  {
    path: ROUTES.user,
    element: User,
  },
  {
    path: ROUTES.invoices,
    element: Invoices,
  },
  {
    path: ROUTES.deals,
    element: AdminDeals,
  },
  {
    path: ROUTES.investors,
    element: Investors,
  },
  {
    path: ROUTES.companies,
    element: AdminCompanies,
  },
  {
    path: ROUTES.founders,
    element: Founders,
  },
  {
    path: ROUTES.founder,
    element: Founder,
  },
  {
    path: ROUTES.documents,
    element: Documents,
  },
  {
    path: ROUTES.shareExchangeRequests,
    element: ShareExchangeRequests,
  },
  {
    path: ROUTES.shareExchangeReview,
    element: AdminShareExchangeReview,
  },
  {
    path: ROUTES.dueDiligence,
    element: DueDiligence,
  },
  {
    path: ROUTES.companiesIac,
    element: CompanyIac,
  },
  {
    path: ROUTES.companyDueDiligence,
    element: DueDiligence,
  },
  {
    path: ROUTES.companyIac,
    element: CompanyIac,
  },
  { path: ROUTES.archivedDueDiligence, element: ArchivedCompanyDueDiligence },
  { path: ROUTES.archivedCompanyIac, element: ArchivedCompanyIac },
  {
    path: ROUTES.dealDetails,
    element: DealDetails,
  },
  {
    path: ROUTES.significantControlCompany,
    element: SignificantControlCompany,
  },
  {
    path: ROUTES.auditLogs,
    element: AuditLogs,
  },
  { path: ROUTES.dealParameters, element: DealParameters },
  {
    path: ROUTES.businessSettings,
    element: BusinessSettings,
  },
  {
    path: ROUTES.dealsParameters,
    element: AllDealsParameters,
  },
  {
    path: ROUTES.companyProfile,
    element: AdminCompanyProfile,
  },
  { path: ROUTES.funds, element: Funds },
  { path: ROUTES.investor, element: Investor },
  {
    path: ROUTES.companyInvestors,
    element: CompanyInvestors,
  },
  {
    path: ROUTES.notifications,
    element: Notifications,
  },
];

export const AUTH_ROUTES = (userType: UserTypes): Route[] => [
  ...COMMON_AUTH_ROUTES,
  {
    path: ROUTES.signUp,
    element: SignUp,
  },
  {
    path: ROUTES.companyConfirm,
    element: CompanyConfirm,
  },
  ...(userType !== UserTypes.FOUNDER
    ? [
        {
          path: ROUTES.founderInvite,
          element: FounderInvite,
        },
      ]
    : []),
  { path: ROUTES.investorsInvite, element: InvestorsInvite },
  { path: ROUTES.welcome, element: Welcome },
  { path: ROUTES.forgotPassword, element: ForgotPassword },
  { path: ROUTES.resetPassword, element: SetPassword },
  { path: ROUTES.finishRegistration, element: FinishRegistration },
  { path: ROUTES.resetPasswordSuccess, element: ResetPasswordSuccess },
  { path: ROUTES.signUpInvitedUser, element: SignUpInvitedUser },
];

export const SIDEBAR_DOCUMENTS_ITEM = (isTypeEntity: boolean) => ({
  title: getTitleWithoutMyWord('My Documents', isTypeEntity),
  path: ROUTES.documents,
  icon: DocumentsSvg,
});

export const SIDEBAR_FM_USERS_LINKS: LinkItem[] = [
  {
    title: 'Home',
    path: ROUTES.dashboard,
    icon: HomeSvg,
  },
  {
    title: 'Documents',
    path: ROUTES.documents,
    icon: DocumentsSvg,
  },
  {
    title: 'Companies',
    path: ROUTES.companies,
    icon: FolderSvg,
  },
];

export const SIDEBAR_ADMIN_LINKS = (isTypeEntity: boolean) => [
  {
    title: 'Home',
    path: ROUTES.dashboard,
    icon: HomeSvg,
  },
  {
    title: 'Users',
    path: ROUTES.users,
    icon: UserSvg,
  },
  {
    title: 'Documents',
    path: ROUTES.documents,
    icon: DocumentsSvg,
  },
  {
    title: 'Requests & Exchanges',
    path: ROUTES.shareExchangeRequests,
    icon: SavingsSvg,
  },
  {
    title: 'Deals',
    path: ROUTES.dealsParameters,
    icon: SyncSvg,
  },
  {
    title: 'Investors',
    path: ROUTES.investors,
    icon: InvestorsSvg,
  },
  {
    title: 'Companies',
    path: ROUTES.companies,
    icon: FolderSvg,
  },
  {
    title: 'Founders',
    path: ROUTES.founders,
    icon: FoundersSvg,
  },
  {
    title: getTitleWithoutMyWord('My Invoices', isTypeEntity),
    path: ROUTES.invoices,
    icon: WalletSvg,
  },
  {
    title: 'Audit Logs',
    path: ROUTES.auditLogs,
    icon: DocumentsSvg,
  },
  { title: 'Funds', path: ROUTES.funds, icon: FundsSvg },
];

export const SIDEBAR_USER_LINKS = (userType: UserTypes, isTypeEntity: boolean): LinkItem[] => [
  {
    title: 'Home',
    path: ROUTES.dashboard,
    icon: HomeSvg,
  },
  ...(userType === UserTypes.FOUNDER
    ? [
        SIDEBAR_DOCUMENTS_ITEM(isTypeEntity),
        {
          title: getTitleWithoutMyWord('My Companies', isTypeEntity),
          path: ROUTES.myCompanies,
          icon: FolderSvg,
        },
      ]
    : [
        {
          title: getTitleWithoutMyWord('My Investments', isTypeEntity),
          path: ROUTES.myInvestments,
          icon: MyInvestmentsSvg,
        },
        {
          title: getTitleWithoutMyWord('My Share Exchanges', isTypeEntity),
          path: ROUTES.deals,
          icon: SyncSvg,
        },
        {
          title: 'Companies',
          path: ROUTES.investments,
          icon: AddInvestmentsCompaniesSvg,
        },
        SIDEBAR_DOCUMENTS_ITEM(isTypeEntity),
        { title: 'Share Exchange Details', path: ROUTES.shareExchangeProcess, icon: InfoSvg },

        ...(isTypeEntity
          ? [
              {
                title: 'Audit Logs',
                path: ROUTES.entityAuditLogs,
                icon: DocumentsSvg,
              },
            ]
          : []),
      ]),
];

export const SIDEBAR_SETTING_LINK: LinkItem = {
  title: 'Settings',
  path: ROUTES.settings,
  icon: SettingsSvg,
};

export const SIDEBAR_FAQ_LINK: LinkItem = {
  title: 'FAQ',
  path: FAQ_LINK,
  icon: FAQSvg,
  target: '_blank',
};

export const SIDEBAR_BUSINESS_SETTINGS_LINK: LinkItem = {
  title: 'Business Settings',
  path: `/admin${ROUTES.businessSettings}`,
  icon: CompanySvg,
};

export const SIDEBAR_ADDITIONAL_LINKS: LinkItem[] = [
  {
    title: 'Resources',
    path: RESOURCES_LINK,
    icon: WorldSvg,
    target: '_blank',
  },
  {
    title: 'Contacts',
    path: CONTACTS_LINK,
    icon: ShareSvg,
    target: '_blank',
  },
];
